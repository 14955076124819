<template>
<div v-if="perms.includes('Facturation Clients') || perms.includes('Admin')">
     <div v-if="loading">
          <div id="loading-bg">
      <div class="loading">
        <div class="effect-1 effects"></div>
        <div class="effect-2 effects"></div>
        <div class="effect-3 effects"></div>
      </div>
    </div>
  </div>
    <b-card v-else >
        <!-- input search -->
        <div class="custom-search">
        <b-row>
            
            <b-col md="3" >
            <b-form-group>
                <b-button
                variant="primary"
                :to="{ name: 'devis-add'}"
            >
                Nouveau Devis
            </b-button>
            </b-form-group>
            </b-col>
            <!-- <b-col md="3" ></b-col> -->
            <b-col md="9">
            <b-form-group>
            <div class="d-flex align-items-center">
            <label class="mr-1">Chercher</label>
            <b-form-input
                v-model="searchTerm"
                placeholder="Chercher ...."
                type="text"
                class="d-inline-block"
            />
            </div>
        </b-form-group>
            </b-col>
        </b-row>
        </div>
       
               <!-- table -->
        <vue-good-table
        :columns="columns"
        :rows="rows"
        :rtl="direction"
        responsive
        :search-options="{
            enabled: true,
            externalQuery: searchTerm }"
        :pagination-options="{
            enabled: true,
            perPage:pageLength
        }"
        >
        <template
            slot="table-row"
            slot-scope="props"
        >

        <div
          v-if="props.column.field === 'remise'"
          class="text-nowrap"
        >
          <span class="text-nowrap">{{ props.row.remise }} %</span>
        </div>
        <div v-else-if="props.column.field === 'NumDevis'">
             <b-avatar
          :id="`invoice-row-${props.row.id}`"
          size="32"
          :variant="`light-${getStatuDevis(props.row.id_devis).variant}`"
        >
          <feather-icon
            :icon="getStatuDevis(props.row.id_devis).icon"
          />
        </b-avatar>
        <b-tooltip
          :target="`invoice-row-${props.row.id}`"
          placement="top"
        >
          <p class="mb-0">
            {{ props.row.NumDevis }}
          </p>
          <p class="mb-0" v-if='props.row.id_devis !== null' >
            Est un : Bon de livraison
          </p>
           <p class="mb-0" v-else-if='props.row.id_devis === null' >
            Est un : Devis 
          </p>
          <p class="mb-0">
            La Date: {{ StringTodate(props.row.DateDevis) }}
          </p>
        </b-tooltip>
          <span class="text-nowrap">{{ props.row.NumDevis }} </span>  
       
   
        </div>
        <div
          v-else-if="props.column.field === 'TotalHT'"
          class="text-nowrap"
        >
          <span class="text-nowrap">{{ props.row.TotalHT }} Dhs</span>
        </div>
        <div
          v-else-if="props.column.field === 'TotalNet'"
          class="text-nowrap"
        >
          <span class="text-nowrap"><b>{{ props.row.TotalNet }} Dhs</b></span>
        </div>
         <span v-else-if="props.column.field === 'bl'">
            <b-button v-if="props.row.id_devis === null"
                variant="gradient-info"
                class="btn-icon"
                @click="TransformBL(props.row.id)">
                <feather-icon icon="NavigationIcon" />
                        <!-- <i data-feather='navigation'></i> -->
            </b-button>
            <b-button v-else-if="props.row.id_devis !== null"
                variant="gradient-warning"
                class="btn-icon"
                @click="TransformBL(props.row.id)"
                disabled
                >
                <feather-icon icon="NavigationIcon" />
                        <!-- <i data-feather='navigation'></i> -->
            </b-button>
          </span>
           <span v-else-if="props.column.field === 'DateDevis'">
              <span class="text-nowrap">{{ StringTodate(props.row.DateDevis) }}</span>
             </span>
           <span v-else-if="props.column.field === 'action'">
                 <span>
                <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
                >
                <template v-slot:button-content>
                    <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                    />
                </template>
                <b-dropdown-item @click="$router.push({ name: 'devis-preview', params: { id: props.row.id }})"  >
                  <feather-icon
                    icon="EyeIcon"
                    size="16"
                    class="mr-50"
                />
            <span>Télecharger</span>
            
                </b-dropdown-item>
                <b-dropdown-item  @click="$router.push({ name: 'devis-update', params: { id: props.row.id }})" >
                    <feather-icon
                    icon="Edit2Icon"
                    class="mr-50"
                    
                    />
                    <span>Modifie</span>
                </b-dropdown-item>
                <b-dropdown-item  @click="DeleteDevis(props.row.id)">
                    <feather-icon
                    icon="TrashIcon"
                    class="mr-50"
                    />
                    <span>Supprimer</span>
                </b-dropdown-item>
                </b-dropdown>
            </span>
            <!-- <b-row>  <b-button 
                        variant="gradient-success"
                        class="btn-icon"
                         @click="$router.push({ name: 'devis-update', params: { id: props.row.id }})"
                        >
                        <feather-icon icon="Edit2Icon" />
                        
                    </b-button>
                    <b-button
                        variant="gradient-danger"
                        class="btn-icon"
                         @click="DeleteDevis(props.row.id)"
                        >
                        <feather-icon icon="TrashIcon" />
                        
                    </b-button>
                     <b-button
                        variant="gradient-info"
                        class="btn-icon"
                         @click="DeleteDevis(props.row.id)"
                        >
                        <feather-icon icon="EyeIcon" />
                        
                    </b-button>
                    
                    </b-row> -->
                    
                    
           
            </span>

            <!-- Column: Common -->
            <span v-else>
            {{ props.formattedRow[props.column.field] }}
            </span>
        </template>

        <!-- pagination -->
        <template
            slot="pagination-bottom"
            slot-scope="props"
        >
            <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap">
                Showing 1 to
                </span>
                <b-form-select
                v-model="pageLength"
                :options="['25','50','100']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap "> of {{ props.total }} entries </span>
            </div>
            <div>
                <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
                >
                <template #prev-text>
                    <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                    />
                </template>
                <template #next-text>
                    <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                    />
                </template>
                </b-pagination>
            </div>
            </div>
        </template>
        </vue-good-table>

    </b-card>
  
  <div>


 
  </div>

    <!--   <sidebar-add-product /> -->
</div>
<div v-else>
  <not-authoriz></not-authoriz>
</div>
</template>

<script>
import config from '@/config'
import moment from 'moment';
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BCard,BRow, BCol,BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, 
  BDropdownItem,BTooltip,BButton,VBToggle,BCollapse,BSidebar,BForm,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import Ripple from 'vue-ripple-directive'
//  import SidebarAddProduct from './SidebarAddProduct.vue'
import vSelect from 'vue-select'
import NotAuthoriz from '../NotAuthoriz.vue'


export default {
      directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
      BCard,BCollapse,
    BCardCode,BRow, BCol,
    VueGoodTable,
    BAvatar,vSelect,BSidebar,BForm,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BTooltip,
    BButton,NotAuthoriz
    // SidebarAddProduct,
  },
  data() {
    return {
      loading :false,
        showSide:true,
        pageLength: 10,
        dir: false,
        columns: [
           {
          label: 'N° Devis',
          field: 'NumDevis',
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher ',
          },
        },
        {
          label: 'Nom Client',
          field: 'NomSociete',
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher ',
          },
        },
       
        {
          label: 'Total H.T',
          field: 'TotalHT',
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher..',
          },
        },
        {
          label: 'Remise %',
          field: 'remise',
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher..',
          },
        },
        {
          label: 'Total NET',
          field: 'TotalNet',
          filterOptions: {
            enabled: true,
            placeholder: 'Search..',
          },
        },
        {
          label: 'Date',
          field: 'DateDevis',
          filterOptions: {
            enabled: true,
            placeholder: 'N° client.. ',
          },
        },
         {
          label: 'B.L',
          field: 'bl',
        },
        
        {
          label: 'Action',
          field: 'action',
        },
      ],
      rows: [],
      searchTerm: '',
      perms:[],
      UserID:{},
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        cheque : 'light-success',
        ESPECE     : 'light-warning',
        CHEQUE : 'light-success',
        espece     : 'light-warning',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
        this.perms=JSON.parse(localStorage.getItem('access'));
        this.GetDevis();
        this.UserID=JSON.parse(localStorage.getItem('userData'))

  },
  methods:{
    GetDevis(){
      this.loading = true
        this.$http.get(`${config.API_BASE_URL}/api/auth/getDevis`).then(res => { this.rows = res.data,this.loading = false })
      },
    StringTodate(val){
        return moment(val).format('DD/MM/YYYY');
      },
    DeleteDevis(id){
        let data= new FormData;
        data.append('UserID', this.UserID.id);
        this.$swal.fire({
            text: "Vous voulez supprimer le devis ?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui, supprimer!'
            }).then((result) => {
            if (result.value) {
                this.$http.post(`${config.API_BASE_URL}/api/auth/DeleteDevis/${id}`,data)
                    .then(res=> {
                        if(res.data=='Success'){
                        this.$swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: 'Le devis est bien supprimé',
                        showConfirmButton: false,
                        timer: 1500  })   
                        this.GetDevis(); 
                        }else if(res.data=='error'){
                            this.$swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Vous pouvez pas supprimer ce devis, Il faut supprimer les produits associés à ce devis!! '
                            });
                        }    
                    }).catch(() => {
                        this.$swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Something went wrong! Pleaz try again'
                            });
                    })
                }             
            }) 
    },
    TransformBL(id){
        let data= new FormData;
        data.append('UserID', this.UserID.id);
        this.$swal.fire({
        text: "Vous voulez vraiment transformer au Bon de livraison?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Oui, transformer'
        }).then((result) => {
         if (result.value) {
            this.$http.post(`${config.API_BASE_URL}/api/auth/DevisToBL/${id}`,data)
                .then(res=> {
                    if(res.data=='Success'){
                    this.$swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Le devis est bien transformé en BL',
                    showConfirmButton: false,
                    timer: 1500  })   
                    this.GetDevis(); 
                    }else{
                        this.$swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: `Erreur de transformation ! Merci d'essayer encore.`
                        });
                    }    
                }).catch(() => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong! Pleaz try again'
                        });
                })
            }             
        })
    },
     getStatuDevis(status) {
    if (status !== null) return { variant: 'success', icon: 'CheckCircleIcon' }
    if (status === null) return { variant: 'danger', icon: 'ArrowDownCircleIcon' }


    return { variant: 'secondary', icon: 'XIcon' }
  }
  },
 
}
</script>
<style scoped>
/* a.btn.btn-icon.btn-gradient-success.collapsed {

    margin-right: 6px;
    margin-left: 8px;
} */

</style>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-select.scss';

</style>
